.contact-form .react-tel-input .form-control {
    background: #E1FFE5!important;
    border: 0;
    border-radius: 10px !important;
    color: #495057;
    font-size: 13px!important;
    margin-bottom: 11px!important;
    padding: 18.5px 14px 18.5px 60px!important;
    transition: box-shadow .25s ease,border-color .25s ease;
    
}