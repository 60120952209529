@font-face {
  font-family: "American Typewriter";
  src: url("./font-m/AMERICAN-TYPEWRITER-REGULAR-_1_.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "American Typewriter", serif;
}

:root {
  --main-color: #8e44ad;
  --black: #222;
  --white: #fff;
  --light-black: #777;
  --light-white: #fff9;
  --dark-bg: rgba(0, 0, 0, 0.7);
  --light-blue: #eee;
  --border: 0.1rem solid #bbb0b0;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.3);
}

* {
  font-family: "roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;

  /* text-transform: capitalize; */
}

.nav {
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  background: rgba(230, 230, 230, 1);
  justify-content: space-between;
  align-items: center;
}

.nav__toggle {
  position: absolute;
  cursor: pointer;
  margin: 0rem 1rem;
  right: 0;
  top: 59px;
}

.close,
input[type="checkbox"] {
  display: none;
}

.hamburger {
  /* margin-top: 0.9rem; */
  position: relative;
  margin-top: -5.1rem;
  z-index: 99;
}

.nav__menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 0rem 0.4rem 0rem 3rem;
  padding: 12px 0px 12px 0px;
}

.nav__menu li .active {
  border-bottom: 2px solid #000;
  padding: 0px 8px 3px;
}

.nav__menu li {
  list-style: none;
  display: none;
}

/* .nav__menu li:first-child {
    margin-right: auto;
    display: block;
  } */
.nav__menu a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 0px 8px 3px;
  /* padding: 4px 0px 4px 0px; */
}

.nav__menu a:hover {
  background: rgba(246, 246, 246, 1);
  /* padding: 4px 0px 4px 0px; */
  /* border-bottom: 2px solid #000; */
  padding: 5px 8px 5px;
}

.nav__menu a:active {
  font-weight: 600;
  background: rgba(230, 230, 230, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  /* padding: 4px 0px 4px 0px; */
}

.head-btn {
  text-decoration: none;
  background: linear-gradient(180deg, #85c93d 0%, #4d9623 103.75%);
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 24px 10px 24px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-bottom: unset !important;
}

.head-btn:hover {
  text-decoration: none;

  background: linear-gradient(
    100.5deg,
    #7eb149 0.42%,
    #5f953e 102.18%
  ) !important;
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 24px 10px 24px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-bottom: unset !important;
}

#nav__checkbox:checked ~ ul.nav__menu li {
  display: block;
}

#nav__checkbox:checked ~ label.nav__toggle .hamburger {
  display: none;
}

#nav__checkbox:checked ~ label.nav__toggle .close {
  display: block;
}

.logo-main {
  margin: 0rem 1rem 0rem 1rem;
}

.waveContainer {
  height: 100vh;
  overflow: hidden;
}

.viewBox {
  height: 100%;
  width: 100%;
}

.path {
  stroke: none;
  fill: rgb(237, 115, 115);
}

/*header*/

.sec-bg {
  /* background: #000; */
  margin-top: -1px;
}

.title12 {
  margin-top: 53px;
  margin-bottom: 60px;
}

#box-second {
  /* margin-top: 388px!important; */
  background: #000 !important;
}

.container {
  max-width: 1258px;
}

.content-value {
  min-height: 626px;
  position: relative;
}

.nav-one {
  background: rgba(230, 230, 230, 1);
  position: fixed;
  z-index: 9;
  width: 100%;
}

.main-section {
  padding: 4rem 5%;
  background: #fff;
  margin-top: -1px;
}

.bg-img {
  background-image: url(../images/clean-world-banner1.png);
  min-height: 558px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -16px;
}

.form-contain {
  position: absolute;
  right: 0;
  margin: 120px 24px 83px;
  max-width: 300px;
  padding: 17px 23px 6px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  width: 488px;
  height: 353px;
}

.form-contain h1 {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.form-contain span {
  font-weight: 400;
  font-size: 32px;
  color: rgba(25, 167, 206, 1);
}

.form-contain p {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 11px;
  text-align: center;
}

.form-group input {
  width: 100%;
  height: 31px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  margin: 5px 0px 3px;
  color: #fff;
  padding: 0px 24px 0px;
}

.input-icons img {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon-m {
  margin: 13px 4px;
  /* padding: 14px 6px 11px; */
  min-width: 4px;
  width: 14px;
  height: 14px;
}

.form-group .btn {
  background: rgba(37, 109, 133, 1);
  color: #fff;
  font-weight: 400;
  border: 1px solid rgba(37, 109, 133, 1);
  border-radius: 5px;
  padding: 5px 15px 5px;
}

.form-group .btn:hover {
  background: rgb(47 127 154);
}

.form-group .btn:focus {
  background: rgb(52, 134, 161);
}

.b-btn {
  text-align: right;
}

.text-banner {
  position: absolute;
  top: 43%;
  bottom: 0%;
  left: 4%;
}

.text-banner h1 {
  color: #fff;
  font-size: 57px;
  font-weight: 400;
  margin-left: -22px;
}

.img-btn {
  width: 98px;
}

.boxes {
  /* width:50%; */
  /* margin:0 auto; */
  justify-content: flex-start;
  display: flex;
  gap: 23px;
  align-items: center;
}
.box-list li {
  color: #fff;
}

.box {
  display: inline-block;
  width: 24%;
  /* height: 15em; */
  text-align: center;
  /* line-height: 15em; */
  color: #fff;
  margin: 3px 17px 3px 17px;
  border-radius: 14px;
  padding: 17px 15px 17px 15px;
}

.one-s {
  background: rgba(48, 1, 52, 1);
}

.two-s {
  background: rgba(0, 54, 77, 1);
}

.three-s {
  background: rgba(4, 36, 53, 1);
}

.four-s {
  background: rgba(87, 14, 30, 1);
}

.boxes h3 {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  margin: 3px;
}

.boxes p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 1px 0px 13px;
}
.box-z {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  min-height: 200px;
  color: #fff;
}
.img-app {
  display: flex;
  gap: 8px;

  justify-content: center;
}

.title-wrapper h2 {
  font-size: 50px;
  font-weight: 100;
  text-align: left;
  background: linear-gradient(180deg, #85c93d, #4d9623 103.75%);
  -webkit-background-clip: text;
  color: transparent;
}

.title-wrapper1 h2 {
  font-size: 50px;
  font-weight: 100;
  text-align: left;
  background: linear-gradient(180deg, #85c93d, #4d9623 103.75%);
  -webkit-background-clip: text;
  color: transparent;
}

.title-wrapper span {
  font-size: 50px;
  font-weight: 400;
  background: linear-gradient(180deg, #85c93d, #4d9623 103.75%);
  -webkit-background-clip: text;
  color: transparent;
}

.title-wrapper1 span {
  font-size: 50px;
  font-weight: 400;
  background: linear-gradient(180deg, #85c93d, #4d9623 103.75%);
  -webkit-background-clip: text;
  color: transparent;
}

.title-wrapper p {
  color: (0, 0, 0, 1);
  font-weight: 400;
  font-size: 20px;
  text-align: justify;
}

.title-wrapper h5 {
  font-weight: 400;
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.title-wrapper h6 {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.client-rating {
  color: #ffb900;
}

.test-btn {
  align-items: baseline;
  background-color: initial;
  border: 1px solid #4d9623;
  border-radius: 5px;
  color: #000;
  display: grid;
  grid-template-columns: 112px 26px;
  justify-content: space-between;
  outline: medium none invert;
  outline: initial;
  padding: 8px 17px;
}

.test-btn:hover {
  border: 1px solid #4d9623;
  color: #fff;
  transition: background-color 0.3s;
  background: linear-gradient(180deg, #85c93d 0%, #4d9623 103.75%);
}
.test-btn:focus {
  outline: unset !important ;
}
.mob-img {
  width: 100%;
}

.mob-img1 {
  width: 100%;
}

.md-pb {
  text-align: left;
}

.md-pb1 {
  text-align: right;
}

.main-bg2 {
  /* background-image: url(../images/Frame2.png);
  width: 100%;
   height: 125vh;
    min-height: 766px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    align-items:center;
    display: flex;

    background-size: 100% 65%; */
  background-image: url(../images/Frame2.png);
  width: 100%;
  /* height: 125vh; */
  /* min-height: 766px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;
  background-size: 100% 100%;
}

.main-bg3 {
  background-image: url(../images/Frame3.png);
  width: 100%;
  /* height: 125vh;
    min-height: 743px; */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;

  background-size: 100% 100%;
}

.main-bg4 {
  background-image: url(../images/Frame4.png);
  width: 100%;
  /* height: 125vh;
    min-height: 743px; */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;

  background-size: 100% 100%;
}

.h2-b {
  margin-bottom: -91px;
  margin-left: 10px;
}

.h1-b1 {
  margin-bottom: 30px;
}

.hd1 {
  margin-top: 88px;
}

.right-cent {
  text-align: right;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: right;
}

.center-content {
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.video-one {
  width: 100%;
  height: 323px;
}

.uvp-list ul {
  display: inline-block;

  padding: 1rem 2rem;
  /* max-width: 320px; */
}

.uvp-list ul {
  list-style: none;
}

.uvp-list ul > li {
  clear: left;
  padding: 0.5rem 0;
}

.uvp-list ul > li:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  display: block;
  float: left;
  margin-left: -1.85rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.uvp-list ul > li:before {
  background: url("https://info.openpath.com/hubfs/checkmark-round-orange.svg");
  background-size: cover;
  background-position: center;
  padding: 0.15rem;
}

.title-wrapper h3 {
  color: rgba(6, 40, 61, 1);
  font-weight: 400;
  font-size: 24px;
}

.direct-dot {
  list-style: none;
}

.video-container {
  height: 280px;
  width: 294px;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 8px;
}

.video-container-b video {
  width: 94%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 8px;
}

.video-container2 {
  height: 280px;
  width: 177px;
  position: relative;
}

/* .breadcrumb-area1 {
  position: relative;
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 226px 0 27px;
  z-index: 1;
} */
.breadcrumb-area1 {
  position: relative;
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 68px 0 0px;
  z-index: 1;
}
.strip-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.strip-banner1 img {
  width: 100%;
  height: 250px;
}
.strip-main h1 {
  color: #fff !important;
  line-height: 39px !important;
  text-shadow: 1px 1px 2px #0e3400, 0 0 1em #000, 0 0 0.2em #000;
  font-size: 40px;
}

.title h1 {
  color: #fff !important;
  line-height: 39px !important;
  text-shadow: 1px 1px 2px #0e3400, 0 0 1em black, 0 0 0.2em black;
}

.frame-8562 {
  background: linear-gradient(
      to left,
      rgba(6, 40, 61, 0.84),
      rgba(6, 40, 61, 0.84)
    ),
    linear-gradient(to left, #e0e0e0, #e0e0e0);
  border-radius: 8px;
  width: 177px;
  height: 254px;
  position: absolute;
  left: 1104px;
  top: 128px;
  overflow: hidden;
}

.name {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2em;
  line-height: 1;
  margin: 0 0 1.5em;
}

.name span {
  font-weight: 300;
}

.gallery-img {
  display: block;
  width: 100%;
}

.gallery {
  gap: 13px;
  width: 100%;
  height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.gallery-b {
  gap: 0px;
  width: 100%;
  height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  top: 61px;
  position: relative;
  margin-bottom: 73px;
}

.photo-container {
  margin-bottom: 3em;
  text-align: center;
  margin-left: 0.8em;
  margin-right: 0.8em;
}

.photo {
  position: relative;
  line-height: 0;
  overflow: hidden;
  margin-bottom: 2.5em;
}

.photo img {
  transform-origin: 0 0;
}

.photo:hover img {
  transform: scale(1.2);
}

/*---*/

.show {
  right: 3% !important;
}

/* ====  WRAPPER ==== */
.wrapper {
  width: 100%;
  padding-left: 1.7rem;
  padding-right: 1.7rem;
  padding-top: 5rem;
  margin-bottom: 5rem;
}

.grid-cols-2 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  /* vertical-align: middle; */
  align-items: center;
}

.grid-item-1 {
  padding-top: 0rem;
  padding-left: 1.5rem;
  text-align: right;
  margin-top: -159px;
}

.grid-item-1 p {
  color: rgba(64, 64, 64, 1);
  font-size: 30px;
  font-weight: 500;
  line-height: 33px;
  text-align: right;
}

.grid-item-1 h1 {
  color: #509507;
  font-weight: 700;
  font-size: 43px;
  font-family: "roboto", sans-serif;
  text-align: right;
  line-height: 50px;
  margin-bottom: 24px;
}

.info-text-m {
  font-family: "American Typewriter", serif;
  font-weight: 400;
  line-height: 32px;
  font-size: 28px;
  padding: 0.3rem;
  padding-bottom: 27px;
  text-align: left;
}

.frame-1048 {
  z-index: 999;
  background: #72b8cf;
  margin-top: -205px;
  height: 109px;
  position: relative;
  overflow: hidden;
}

.btn_wrapper {
  margin-top: 3.5rem;
  display: flex;
  width: 100%;
}

.view_more_btn {
  width: 180px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  letter-spacing: 0;
  color: #fff;
  font-weight: 500;
  margin-right: 10px;
  box-shadow: 0 0.5rem 1.5rem rgba(22, 28, 45, 0.1);
}

.view_more_btn i {
  margin-left: 0.7rem;
}

.view_more_btn:hover {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.grid-item-2 {
  width: 100%;
  height: 100%;
  /* padding-top: 5rem; */
}

/* .team_img_wrapper {
  width: 500px;
  max-width: 100%;
  height: 440px;
} */
.team_img_wrapper {
  width: 500px;
  max-width: 100%;
  height: 440px;
  position: absolute;
  margin-right: 0px;
  /* left: 50%; */
  bottom: 164px;
}

.team_img_wrapper img {
  width: auto;
  height: 34rem;
  object-fit: contain;
}

.grid-cols-3 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  padding: 1rem;
}

.grid-col-item {
  height: 100%;
}

.icon {
  width: 100%;
  line-height: 40px;
}

.icon svg {
  width: 30px;
  height: 30px;
  color: #6b85d8;
}

.featured_info {
  width: 100%;
}

.featured_info span {
  width: 100%;
  display: block;
  font-size: 21px;
  line-height: 33px;
  color: var(--lg-heading);
}

.featured_info p {
  display: block;
  width: 100%;
  margin-top: 7px;
  font-weight: 400;
  color: #334157;
  line-height: 25px;
  font-size: 15.5px;
}

/* .bg-wrap {
  background-image: url(../images/main-banner.png);
  min-height: 673px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -4px;
} */

/*banner strip*/

/* .photo1 {
  width: auto;
  height: 166px;
  margin-top: -150px;
  position: relative;
  float: right;

} */
.photo1 {
  width: auto;
  height: 166px;
  position: relative;
  /* margin-top: -180px; */
  float: right;
  bottom: 139px;
}

.banner-strip {
  margin-right: -26px;
  margin-left: -28px;
  background: #81c53a;
  /* height: 105px; */
  margin-top: 15px;
  margin-bottom: 0;
  bottom: 0px;
  position: absolute;
  width: 100%;
}

/* .bg-wrap {
  background-image: url(../images/main-banner.jpg);
  padding-top: 0px;
  align-items: center;

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  margin-top: -4px;
  position: relative;
  vertical-align: middle;
} */
.bg-wrap {
  background-image: url(../images/webp/main-banner_resized.webp);
  padding-top: 156px;
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100vh;
  margin-top: -4px;
  /* min-height: 673px; */
  position: relative;
  vertical-align: middle;
  /* align-items: flex-start; */
}

/* .banner-p {
  padding-top: 37px;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  font-style: oblique;
  padding-right: 111px;
  line-height: 30px;
} */
.banner-p {
    color: #fff;
    font-size: 22px;
    font-style: oblique;
    font-weight: 400;
    line-height: 30px;
    padding-right: unset;
    padding-top: unset;
    text-align: center;
    margin: 19px 0px 19px;
}

.quote-n {
  font-size: 20px;
  display: block;
  width: 89%;
  text-align: right;
  font-weight: 400;
  margin-top: -6px;
}

.f-head {
  display: grid;
  gap: 18px;
}
.f-head p span {
  color: transparent;
  background: linear-gradient(180deg, #86ca3d, #539c26);
  -webkit-background-clip: text;
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Montserrat Alternates", sans-serif !important;
}
.f-head img {
  width: 90%;
  height: auto;
}
.f-head p {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
#box-four {
  padding: 30px 20px 30px 20px;
  background: rgba(38, 38, 38, 1) !important;
}
.nxt-one p span {
  color: transparent;
  background: linear-gradient(180deg, #86ca3d, #539c26);
  -webkit-background-clip: text;
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Montserrat Alternates", sans-serif !important;
}
.nxt-one {
  align-items: center;
  display: grid;
  gap: 27px;
  justify-content: center;
}
.nxt-one p {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0px;
}
.img-row {
  display: flex;
  justify-content: center;
}
.img-row img {
  width: 90px;
}
.nxt-two {
  display: grid;
  align-items: center;
  justify-content: center;
}

/*About */

.title-about h2 {
  color: #000;
  font-size: 32px;
  font-weight: 400;
  text-align: left;
}

.title-about h4 {
  color: rgba(64, 64, 64, 1);
  font-weight: 400;
  font-size: 28px;
  margin: 14px 0px 18px;
}

.title-about p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
}

.circle {
  display: flex;
  align-items: center;
  gap: 41px;
}

.circle-1 {
  background: rgba(243, 200, 255, 1);
  width: 168px;
  border-radius: 50%;
  color: rgba(64, 4, 81, 1);
  font-size: 18px;
  font-weight: 600;
  height: 168px;
  padding: 72px 65px;
}

.circle-2 {
  background: rgb(235 203 153);
  width: 168px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: rgb(103 71 12);
  height: 168px;
  padding: 72px 62px;
}

.circle-3 {
  background: #ecffda;
  border-radius: 50%;
  color: #31580b;
  font-size: 18px;
  font-weight: 600;
  height: 168px;
  padding: 65px 55px;
  width: 168px;
  line-height: 21px;
}

.circle-4 {
  background: #dafaff;
  border-radius: 50%;
  color: #102885;
  font-size: 18px;
  font-weight: 600;
  height: 168px;
  padding: 70px 26px;
  width: 168px;
  line-height: 30px;
}

.circle-s {
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
}

.wrap {
  /* overflow: hidden; */
  margin: 10px;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 116px;
}

.box-l {
  float: left;
  position: relative;
  width: 24%;

  padding-bottom: 9%;

  align-items: center;
  display: flex;
}

.boxInner {
  position: absolute;
  /* left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px; */
  overflow: hidden;
}

.boxInner img {
  width: 100%;
}

.-fx-image-gal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  justify-content: center;
}

.-fx-gal-item {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.-fx-gal-item a {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.-fx-gal-image-thumb {
  margin: 12px;
  height: 227px;
}

.video-container-b {
  height: 215px;
  width: 294px;
  position: relative;
  margin-bottom: 23px;
}

/*features*/

.features-title {
  margin-right: 9px;
}

.features-title2 {
  margin-left: 9px;
}

.img-left {
  text-align: right;
}

.features-title h2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin-left: 0px;
}

.top-base {
  align-items: center;
  display: grid;
}

.features-title p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: right;
}

.features-title ul li {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.features-title ul {
  margin-left: 15px;
}

.features-title2 h2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin-left: -16px;
}

.features-title2 p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.content-top {
  margin-top: 103px;
}

/*flow chart*/

.yes,
.yes * {
  box-sizing: border-box;
}

/* .yes {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 31px;
} */

/* .no {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: absolute;
  left: 590px;
  top: 926px;
  width: 23px;
} */

.tn1 {
  margin-top: 31px !important;
}

.org-chart .board ul .first-li .md-1:before {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  height: 71px !important;
  width: 0px !important;
  border-left: 4px solid rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2) !important;
  margin-right: 49% !important;
  top: 78px !important;
  margin-left: 42% !important;
}
.head-m {
  font-size: 20px;
}

.img-log {
  width: 10%;
}

.content {
  font-family: Verdana;
  font-size: 14px;
  position: relative;
}

.content * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.org-chart {
  display: block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
  margin-top: 62px;
}

.org-chart.cf:before,
.org-chart.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.org-chart.cf:after {
  clear: both;
}

/* .org-chart.cf {
  *zoom: 1;
} */
.org-chart ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.org-chart ul li {
  position: relative;
}

.org-chart ul li span {
  background: #f3c8fe;
  border: 4px solid #888;
  border-radius: 24px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  color: #000;
  display: block;
  font-size: 12px;
  height: 68px;
  height: auto;
  overflow: hidden;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s linear;
  line-height: 15px;
  padding: 9px;
}

.lvl-b {
  border: 2px dashed #535353 !important;
  background: #fff !important;
  z-index: 1;
  position: relative;
}

.sec-p1 {
  margin-top: -13px;
}

.org-chart .board {
  width: 70%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.org-chart .board:before {
  content: "";
  display: block;
  position: absolute;
  height: 91%;
  width: 0px;
  border-left: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin-left: 49%;
  top: 139px;
}

.org-chart ul.columnOne {
  height: 90px;
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnOne li {
  width: 30%;
  margin: 0px auto;
  top: 20px;
}

.org-chart ul.columnTwo {
  position: relative;
  width: 100%;
  display: block;
  height: 90px;
  clear: both;
}

.org-chart ul.columnTwo li:first-child {
  width: 30%;
  float: left;
}

.org-chart ul.columnTwo li {
  width: 30%;
  float: right;
}

/* .org-chart ul.columnTwo:before {
  content: "";
  display: block;
  position: relative;
  width: 80%;
  height: 10px;
  border-top: 2px solid orange;
  margin: 0 auto;
  top: 40px;
} */
.org-chart ul.columnThree {
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnThree li:first-child {
  width: 30%;
  float: left;
  margin-left: 37px;
}

.org-chart ul.columnThree li {
  width: 30%;
  margin-left: 5%;
  float: left;
}

.org-chart ul.columnThree li:last-child {
  width: 30%;
  float: right;
  margin-right: 9px;
}

.org-chart ul.columnThree40 {
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnThree40 li:first-child {
  float: right;
  margin-left: 47px;
  margin-top: -11px;
  width: 30%;
}

.org-chart ul.columnThree40 li {
  width: 30%;
  margin-left: 5%;
  float: left;
}

.org-chart ul.columnThree40 li:last-child {
  width: 30%;
  float: right;
  margin-left: 27px;
  margin-top: 38px;
  left: -8px;
}

.org-chart ul.columnThree3:before {
  content: "";
  display: block;
  position: relative;
  width: 41%;
  height: 4px;
  border-right: 4px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  top: 96px;
}

.org-chart ul.columnThree40:before {
  content: "";
  display: block;
  position: relative;
  width: 35%;
  height: 4px;
  border-top: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  top: 72px;
  left: calc(50% - 223px);
}

.enq-list1 {
  margin-top: 39px !important;
}

.enq-sub {
  margin-top: -53px !important;
  float: right;
}

.enq-sub::before {
  content: "";
  display: block;
  position: relative;
  width: 16%;
  height: 4px;
  border-top: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  top: 13px;
  /* left: calc(50% - 198px); */
  float: left;
}

.org-chart ul.columnThree3 {
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnThree3 li:first-child {
  width: 30%;
  float: left;
  margin-left: 0;
}

.org-chart ul.columnThree3 li {
  float: left;
  margin-left: 5%;
  margin-top: 17px;
  width: 30%;
}

.org-chart ul.columnThree3 li:last-child {
  width: 30%;
  float: right;
  /* margin-left: 27px; */
}

.org-chart ul.columnThree3:before {
  content: "";
  display: block;
  position: relative;
  width: 41%;
  height: 4px;
  border-top: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  top: 55px;
}

.org-chart ul.columnThree30 {
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnThree30 li:first-child {
  width: 30%;
  float: left;
  margin-left: 0;

  margin-top: 14px;
}

.org-chart ul.columnThree30 li {
  width: 30%;
  /* margin-left: 5%; */
  float: left;
}

.org-chart ul.columnThree30 li:last-child {
  width: 30%;
  float: left;
  margin-left: 60px;
  margin-top: 17px;
}

.org-chart ul.columnThree30:before {
  content: "";
  display: block;
  position: relative;
  width: 11%;
  height: 4px;
  border-top: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  top: 50px;
  left: -182px;
}

.org-chart .departments {
  width: 100%;
  display: block;
  clear: both;
}

/* .org-chart .departments:before {
  content: "";
  display: block;
  width: 85%;
  height: 22px;
  border-top: 2px solid orange;
  border-left: 2px solid orange;
  border-right: 2px solid orange;
  margin: 0 auto;
  top: 0px;
} */
/* .org-chart .department {
  border-left: 2px solid orange;
  width: 13.2%;
  float: left;
  margin: 0px 4px;
}
.org-chart .department:after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 22px;
  border-left: 2px solid orange;
  left: 50%;
  top: -22px;
} */
.org-chart .department:first-child:after {
  display: none;
}

.org-chart .department:last-child:after {
  display: none;
}

/* .org-chart .department.central {
  background: #F5EEC9;
} */
.org-chart .department.central:after {
  display: none;
}

/* .org-chart .department span {
  border-left: 1px solid orange;
}
.org-chart .department li {
  padding-left: 25px;
  border-bottom: 2px solid orange;
  height: 80px;
}
.org-chart .department li span {
  background: #92D4A8;
  top: 38px;
  position: absolute;
  z-index: 1;
  width: 95%;
  height: auto;
  vertical-align: middle;
  right: 0px;
  line-height: 14px;
  border: 3px solid orange;
} */
.org-chart .department .sections {
  margin-top: -20px;
}

/* MEDIA QUERIES */
@media all and (max-width: 767px) {
  .org-chart .board {
    margin: 0px;
    width: 100%;
  }

  .org-chart .departments:before {
    border: none;
  }

  /* .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    background: #F5EEC9;
    margin-bottom: 40px;
  }
  .org-chart .department:before {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 60px;
    border-left: 2px solid orange;
    z-index: 1;
    top: -45px;
    left: 0%;
    margin-left: -2px;
  } */
  .org-chart .department:after {
    display: none;
  }

  .org-chart .department:first-child:before {
    display: none;
  }
}

/*--------- TO BE REMOVED FROM YOUR CSS --*/
/* this is just to display the behaviour of responsive on codepen */
.responsive-content {
  /* width: 50%; */
  margin: 0px auto;
}

.responsive-content .org-chart .board {
  margin: 0px;
  width: 100%;
}

.responsive-content .org-chart .departments:before {
  border: none;
}

/* .responsive-content .org-chart .department {
  float: none;
  width: 100%;
  margin-left: 0;
  background: #F5EEC9;
  margin-bottom: 40px;
}
.responsive-content .org-chart .department:before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 60px;
  border-left: 2px solid orange;
  z-index: 1;
  top: -45px;
  left: 0%;
  margin-left: -2px;
} */
.responsive-content .org-chart .department:after {
  display: none;
}

.responsive-content .org-chart .department:first-child:before {
  display: none;
}

.org-chart ul.columnThree3 .nw1:first-child {
  width: 30%;
  float: right;
  margin-left: 0;
}

.org-chart .board ul .first-li:before {
  border-left: 4px solid #888;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  height: 54px;
  margin-left: 50%;
  position: absolute;
  top: 105px;
  width: 0;
}

.org-chart .board ul .md1:before {
  margin-top: -31px;
  margin-left: 42% !important;
  height: 84px;
}

.md2:before {
  border-left: 4px solid #888 !important;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
  content: "" !important;
  display: block !important;
  height: 87px !important;
  margin-left: 41% !important;
  position: absolute !important;
  top: 73px !important;
  width: 0 !important;
}

/* .org-chart .board ul .md2:before {
  margin-top: -31px;
  margin-left: 42% !important;
  height: 84px;
} */

.org-chart ul.columnOne:before {
  content: "";
  display: block;
  position: relative;
  width: 64%;
  /* height: 10px; */
  /* border-top: 2px solid orange; */
  margin: 0 auto;
  top: 47px;
  border-top: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.org-chart ul.columnOne1:before {
  content: "";
  display: block;
  position: relative;
  width: 70%;
  height: 10px;
  /* border-top: 2px solid orange; */
  margin: 0 auto;
  top: 56px;
}

.org-chart ul.columnOne1 {
  height: 90px;
  position: relative;
  width: 100%;
  display: block;
  clear: both;
}

.org-chart ul.columnOne1 li {
  width: 30%;
  margin: 0px auto;
  top: 13px;
}

.enq-sub {
  width: 35% !important;
  margin: 0px auto !important;
  top: -41px !important;
}

.img-ic {
  width: 61%;
}

.org-chart ul.rgt:before {
  content: "";
  display: block;
  position: relative;
  width: 70%;
  height: 10px;
  /* border-top: 2px solid orange; */
  margin: 0 auto;
  top: 56px;
}

.org-chart ul.rgt {
  height: 90px;
  position: relative;
  width: 100%;
  display: flex;
  clear: both;
}

.org-chart ul.rgt li {
  width: 30%;
  margin: 0px auto;
  top: 20px;
}

/* .org-chart ul.rgt:before {
  content: "";
    display: block;
    position: relative;
    width: 37%;
    height: 10px;
    border-top: 2px solid orange;
    margin: 0 auto;
    top: 37px;
    left: calc(50% - 2px);
} */

.org-chart ul.rgt11:before {
  content: "";
  display: block;
  position: relative;
  width: 70%;
  height: 10px;
  /* border-top: 2px solid orange; */
  margin: 0 auto;
  top: 56px;
}

.org-chart ul.rgt11 {
  height: 90px;
  position: relative;
  width: 100%;
  display: flex;
  clear: both;
}

.org-chart ul.rgt11 li {
  left: 75px;
  margin: 0 auto;
  top: 58px;
  width: 30%;
  /* margin-left: 349px; */
}

.org-chart ul.rgt11:before {
  content: "";
  display: block;
  position: relative;
  width: 37%;
  height: 10px;
  /* border-top: 2px solid orange; */
  margin: 0 auto;
  top: 37px;
  left: calc(50% - 2px);
}

.org-chart ul li.rgt1:before {
  left: -33px;
  content: "";
  display: block;
  position: relative;
  width: 36%;
  height: 0px;
  border-right: 4px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.not-one {
  margin: -5px;
}

.org-chart ul li.rgt2:before {
  left: -11px;
  content: "";
  display: block;
  position: relative;
  width: 1%;

  height: 59px;
  /* height: 48px; */
  margin: 0 auto;
  bottom: 6px;
  border-right: 4px solid rgb(136 136 136);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

/*footer*/

.footer-section {
  background: rgba(38, 38, 38, 1);
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid rgba(255, 255, 255, 0.31);
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 28px;
  font-weight: 400;
  line-height: 23px;

  width: 348px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 40px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3b5998;
}

.twitter-bg {
  background: #55acee;
}

.google-bg {
  background: #dd4b39;
}

.footer-widget-heading h3 {
  color: rgba(255, 255, 255, 0.48);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  position: relative;
}

.footer-widget ul li {
  display: flex;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  /* color: #ff5e14; */
  font-weight: 600;
  text-decoration: none;
}

.footer-widget ul li a {
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  /* background: #202020; */
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.foot-center {
  justify-content: center;
}

.foot-ic {
  display: flex;
  margin-bottom: 17px;
}

.foot-ic1 {
  padding-right: 21px;
}

.foot-ic a {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
}

.foot-last {
  justify-content: center;
}

.foot-add {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
}

.footer-social-icon {
  margin-bottom: 20px;
}

/*Contact */
.contact {
  padding: 100px 9px 80px;
}

.contact .heading h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.contact .heading p {
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  color: #606060;
  margin: 20px 0 60px;
  padding: 0;
}

.contact .form-control {
  background: #e1ffe5;
  border: 0;
  border-radius: 10px;
  font-size: 13px;
  margin-bottom: 11px;
  padding: 20px;

  /* padding: 20px;
  font-size: 13px;
  margin-bottom: 11px;
  background: rgb(231 244 252);
  border: 0;
  border-radius: 10px; */
}

.contact button.btn {
  /* padding: 7px; */
  border-radius: 10px;
  font-size: 15px;

  color: #ffffff;
  font-weight: 400;
  height: 39px;
  background: linear-gradient(180deg, #85c93d 0%, #4d9623 103.75%);
}

.contact button.btn:hover {
  color: #ffffff;

  font-weight: 400;
  /* padding: 7px; */
  border-radius: 10px;
  font-size: 15px;
  height: 39px;
  background: linear-gradient(
    100.5deg,
    #7eb149 0.42%,
    #5f953e 102.18%
  ) !important;
  border: 2px solid linear-gradient(100.5deg, #7eb149 0.42%, #5f953e 102.18%) !important;
}

.contact .title h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.contact .title p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 1.6;
  margin: 0 0 40px;
}

.contact .content .info {
  margin-top: 30px;

  display: flex;
}

.contact .content .info i {
  font-size: 30px;
  padding: 0;
  margin: 0;
  color: #ffffff;
  margin-right: 20px;
  text-align: center;
  width: 20px;
}

.contact .content .info h4 {
  font-size: 20px;
  line-height: 1.4;
  color: #fff;
  font-weight: 400;
}

.contact .content .info h4 span {
  font-size: 13px;
  font-weight: 300;
  color: #fff;
}

.address-side {
  background: rgba(38, 38, 38, 1);
  border-radius: 10px;
  height: 35rem;
  margin-top: 13px;
  padding: 31px 26px;
}

.h1-c {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  width: 168px;
  margin-bottom: 25px;
}

h2.h1-c:before,
.h1-b:after {
  background: #ffffff;
  height: 1px;
  flex: 1;
  content: "";
}

.h1-c span {
  font-weight: 400;
  /* background: #fff; */
  margin: 0 15px;
  color: #ffffff;
  font-size: 12px;
}

h2.h1-c:before {
  background: #ffffff;
  height: 1px;
  flex: 1;
  content: "";
}

.h1-c:after {
  background: unset;
}

.other-place {
  text-decoration: underline;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.contact-form {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
  padding: 47px 43px 115px;
  border-radius: 10px;
}

.contact-form {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  height: 35rem;
  margin-top: 13px;
}
.contact-form .react-tel-input .form-control {
  background: #e1ffe5 !important;
  border: 0;
  border-radius: 10px !important;
  color: #495057;
  font-size: 13px !important;
  margin-bottom: 11px !important;
  padding: 18.5px 14px 18.5px 60px !important;
  transition: box-shadow 0.25s ease, border-color 0.25s ease;
}
/* .top-img {
  align-items: center;
  display: grid;
} */

/*Flow chart*/
.org-chart .board ul #f-li:before {
  content: "";
  display: block;
  position: absolute;
  height: 83px;
  width: 0px;
  border-left: 4px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  margin-left: 49%;

  top: 95px;
}

.about11,
.about11 * {
  box-sizing: border-box;
}

.about11 {
  background: #ffffff;
  width: 100%;
  height: 3254px;
  position: relative;
  overflow: hidden;
}

.group-1031 {
  position: absolute;
  inset: 0;
}

.quest-img {
  height: auto;
  margin-left: 89px;
  width: 76px;
  transform: rotate(313deg) scale(1, 1);
  margin-top: 66px;
}

.line-4 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 77px;
  height: 0px;
  position: absolute;
  left: calc(50% - 111px);
  top: 1306px;
  transform-origin: 0 0;
  transform: rotate(180deg) scale(1, 1);
}

.about-p1 ul {
  margin-left: 15px;
}

.frame-1373 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 524px);
  top: 1264px;
}

.frame-1363 {
  padding: 6px 6px 5px 6px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.frame-1364 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.icon-park-solid-message {
  flex-shrink: 0;
  width: 21px;
  height: 21px;
  position: relative;
  overflow: hidden;
}

.mask-group {
  position: absolute;
  inset: 0;
}

.group {
  width: 17.5px;
  height: 17.5px;
  position: static;
}

.group2 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector3 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.quote-inspection-report-to-customer-with-a-request-to-approve {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 276px;
}

.group-1032 {
  position: absolute;
  inset: 0;
}

.line-42 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 65px;
  height: 0px;
  position: absolute;
  left: calc(50% - 106px);
  top: 2350px;
  transform-origin: 0 0;
  transform: rotate(180deg) scale(1, 1);
}

.frame-13732 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 508px);
  top: 2307px;
}

.group3 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector6 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.message-to-customer-regarding-job-scheduled-time-team {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 276px;
}

.group-1033 {
  position: absolute;
  inset: 0;
}

.line-43 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 65px;
  height: 0px;
  position: absolute;
  left: calc(50% - 129px);
  top: 2499px;
  transform-origin: 0 0;
  transform: rotate(180deg) scale(1, 1);
}

.frame-13733 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 534px);
  top: 2468px;
}

.group4 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector9 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.message-to-customer-work-start {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 276px;
}

.group-1035 {
  position: absolute;
  inset: 0;
}

.line-44 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 54px;
  height: 0px;
  position: absolute;
  left: calc(50% - 206px);
  top: 2795px;
  transform-origin: 0 0;
  transform: rotate(180deg) scale(1, 1);
}

.m-one {
  position: relative;
  z-index: 1;
}

.frame-13734 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 516px);
  top: 2752px;
}

.group5 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector12 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.invoice-observation-note-to-customer {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 195px;
}

.group-1034 {
  position: absolute;
  inset: 0;
}

.line-45 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 65px;
  height: 0px;
  position: absolute;
  left: calc(50% - -217px);
  top: 2499px;
  transform-origin: 0 0;
  transform: rotate(180deg) scale(1, 1);
}

.frame-13735 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 305px;
  position: absolute;
  left: calc(50% - -220px);

  top: 2456px;
}

.group6 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector15 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.message-to-customer-work-completion {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 225px;
}

.line-3 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 65px;
  height: 0px;
  position: absolute;
  top: 722px;
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  left: calc(50% - -317px);
}

.frame-1327 {
  width: 39.12px;
  height: 35px;
  position: absolute;
  left: 328px;
  top: 255px;
}

.rectangle-559 {
  background: #ffffff;
  width: 4px;
  height: 109px;
  position: absolute;
  top: 109px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  left: calc(50% - 154px);
}

.rectangle-562 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  /* left: 550px; */
  top: 223px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  left: calc(50% - 154px);
}

.rectangle-563 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: 739px;
  top: 223px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  left: calc(50% - -53px);
}

.rectangle-564 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  /* left: 647px; */
  top: 261px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  left: calc(50% - 2px);
}

.rectangle-565 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  /* left: 651px; */
  top: 409px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  left: calc(50% - 2px);
}

.rectangle-566 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 560px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-569 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  /* left: 714px; */
  top: 1047px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  left: calc(50% - 2px);
}

.rectangle-570 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1195px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-571 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1344px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-572 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1492px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-573 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1641px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-574 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1793px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-575 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 1943px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-576 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2091px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-577 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2238px;

  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-578 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2388px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-579 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2542px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-580 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2688px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-581 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2835px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-582 {
  background: #ffffff;
  width: 4px;
  height: 64px;
  position: absolute;
  left: calc(50% - 2px);
  top: 2982px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
}

.rectangle-568 {
  background: #ffffff;
  width: 4px;
  height: 73px;
  position: absolute;
  left: calc(50% - 2px);
  top: 887px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  /* left: calc(50% - 76px); */
}

.rectangle-567 {
  background: #ffffff;
  width: 4px;
  height: 103px;
  position: absolute;
  top: 759px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  left: calc(50% - -126px);
}

.rectangle-584 {
  background: #ffffff;
  width: 3px;
  height: 171px;
  position: absolute;
  left: 1006px;
  top: 807px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  left: calc(50% - -314px);
}

.rectangle-585 {
  background: #ffffff;
  width: 3px;
  height: 171px;
  position: absolute;
  left: 826px;
  top: 1002px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(-90deg) scale(1, 1);
  left: calc(50% - -114px);
}

.rectangle-560 {
  background: #ffffff;
  width: 4px;
  height: 110px;
  position: absolute;
  /* left: 804px; */
  top: 113px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  left: calc(50% - -115px);
}

.frame-1323 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 10px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: calc(50% - 1605px);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  left: calc(50% - 413px);
}

.frame-1347 {
  padding: 10px 3px 10px 3px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.messages-from-customer {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1324 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 10px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  /* left: 751px; */
  top: calc(50% - 1603px);
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  left: calc(50% - -4px);
}

.frame {
  padding: 15px;
  flex-shrink: 0;
  width: 65px;
  height: 65px;
  position: relative;
  overflow: hidden;
}
.get-btn {
  background: linear-gradient(115.76deg, #7fc33a 20.03%, #59a028 74.31%);
  border: unset;
  border-radius: 8px;
  padding: 15px 33px 15px 33px;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  text-decoration: unset !important;
  font-weight: 600;
}
.get-btn:hover {
  background: linear-gradient(180deg, #85c93d 0%, #4d9623 103.75%);
  color: rgba(255, 255, 255, 1);
}
.group7 {
  position: absolute;
  left: 15.5px;
  top: 15px;
  overflow: visible;
}

.other-number-direct-call {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 199px;
}

.frame-1328 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 317px);
  top: 324px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-1325 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.sales-manager-call-customer-for-address-distance-preferred-time {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1329 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 210px);
  top: 473px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13252 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  width: 65px;
  height: 65px;
  position: relative;
}

.solar-pen-bold {
  position: absolute;
  left: calc(50% - 17px);
  top: calc(50% - 17.5px);
  overflow: visible;
}

.manager-work-order-creation-starting {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1346 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  width: 200px;
  height: 200px;
  position: absolute;
  /* left: 656.42px; */
  top: 614px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  transform-origin: 0 0;
  transform: rotate(45deg) scale(1, 1);
  overflow: hidden;
  left: calc(50% - 2px);
}

.inspection-required {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: absolute;
  left: calc(50% - 93.26px);
  top: 119.13px;
  transform-origin: 0 0;
  transform: rotate(-45deg) scale(1, 1);
}

.questionblue-3-1 {
  width: 88px;
  height: 139px;
  position: absolute;
  left: 49.2px;
  top: 112.02px;
  transform-origin: 0 0;
  transform: rotate(-45deg) scale(1, 1);
}

.frame-1330 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - -226px);
  top: 715px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.carbon-application-mobile {
  position: absolute;
  left: calc(50% - 17.5px);
  top: calc(50% - 17.5px);
  overflow: visible;
}

.supervisor-app {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-13472 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - -226px);
  top: 961px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.carbon-application-mobile2 {
  width: 35px;
  height: 35px;
  position: absolute;
  left: calc(50% - 17.5px);
  top: calc(50% - 17.5px);
  overflow: hidden;
}

.carbon-report {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16.5px);
  overflow: visible;
}

.inspection-report {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1348 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 120px);
  top: 961px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.vector31 {
  position: absolute;
  left: calc(50% - 15.5px);
  top: calc(50% - 19.5px);
  overflow: visible;
}

.work-order-complete {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1349 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 129px);
  top: 1110px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.icon-park-outline-full-selection {
  width: 32px;
  height: 32px;
  position: absolute;
  left: calc(50% - 15.5px);
  top: calc(50% - 16.5px);
  overflow: hidden;
}

.group8 {
  position: absolute;
  left: 2.67px;
  top: 2px;
  overflow: visible;
}

.display-format-selection {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1350 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 167px);
  top: 1408px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13253 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.customer-approval-manual-approval {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1351 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 112px);
  top: 1556px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.group9 {
  position: absolute;
  left: 16.5px;
  top: 10px;
  overflow: visible;
}

.feature-img {
  width: 100%;
}

.job-order-created {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1352 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 116px);
  top: 1707px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13254 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.supervisor-selection {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1354 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 127px);
  top: 2006px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13255 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.select-payment-method {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1356 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 106px);
  top: 2154px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13256 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.advance-collection {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1357 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 133px);
  top: 2303px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.wp-m {
  margin-bottom: 57px;
}

.group10 {
  width: 32px;
  height: 32px;
  position: static;
}

.group11 {
  position: absolute;
  left: 16.5px;
  top: 16px;
  overflow: visible;
}

.vector43 {
  position: absolute;
  left: 12.4px;
  top: 11.9px;
  overflow: visible;
}

.supervisor-to-select-workers {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1358 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 158px);
  top: 2451px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13257 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.lead-worker-to-accept-stock-start-end-work-complete-check-list-prepare-observation-note {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1359 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 162px);
  top: 2606px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.fluent-mdl-2-completed-solid {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  overflow: visible;
}

.supervisor-to-verify-completion-of-work {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1360 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 221px);
  top: 2750px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13258 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.manager-to-complete-observation-note-proceed-invoice {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1361 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 193px);
  top: 2897px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.group12 {
  position: absolute;
  left: 17px;
  top: 16px;
  overflow: visible;
}

.work-lead-to-collect-balance-amount-of-invoice {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1362 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 216px);
  top: 3046px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-13259 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.customer-receives-feedback-form-to-rate-the-service {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1355 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 100px);
  top: 1857px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-132510 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.stock-allocation {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.yes {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: absolute;
  left: calc(50% - 224px);
  top: 54px;
  width: 31px;
  /* position: relative; */
}

.no {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: absolute;
  top: 137px;
  width: 23px;
  left: calc(50% - 383px);
  left: calc(50% - 389px);
  top: 130px;
  width: 23px;
}

.line-2 {
  border-style: dashed;
  border-color: #535353;
  border-width: 2px 0 0 0;
  width: 141px;
  height: 0px;
  position: absolute;
  left: 740px;
  top: 239px;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  left: calc(50% - -21px);
}

.frame-13232 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 10px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 176px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  left: calc(50% - 91px);
}

.group13 {
  position: absolute;
  left: 13px;
  top: 15px;
  overflow: visible;
}

.enquiry-list {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-13502 {
  background: #f3c8fe;
  border-radius: 24px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 131px);
  top: 1259px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}

.frame-132511 {
  padding: 14px 15px 14px 15px;
  flex-shrink: 0;
  position: relative;
  overflow: visible;
}

.send-quote-to-customer {
  color: #000000;
  text-align: center;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
}

.frame-1371 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 884px;
  top: 196px;
  left: calc(50% - -163px);
}

.group14 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector63 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.message-to-customer-acknowledging-enquiry {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 176px;
}

.frame-1372 {
  border-radius: 13px;
  border-style: dashed;
  border-color: #535353;
  border-width: 2px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  left: 856px;
  top: 578px;
  left: calc(50% - -189px);
}

.group15 {
  position: absolute;
  left: 1.75px;
  top: 1.75px;
  overflow: visible;
}

.vector66 {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: visible;
}

.message-to-customer-regarding-site-inspection {
  color: #000000;
  text-align: left;
  font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
  position: relative;
  width: 212px;
}

/*  */

/* ==== MEDIA QURIES FOR RESPONSIVE DESIGN ==== */

@media screen and (max-width: 991px) {
  /* .wrapper {
    padding-top: 3rem;
  } */

  .grid-cols-2 {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .grid-item-1 {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: unset;
  }

  .main-heading {
    font-size: 32px;
    text-align: center;
    line-height: 40px;
  }

  .info-text {
    font-size: 16px;
    text-align: center;
    padding: 2.8rem;
  }

  .btn_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .grid-item-2 {
    order: 1;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .team_img_wrapper {
    width: auto;
    height: 45rem;
    bottom: -103px;
  }

  .featured_info span {
    font-size: 19px;
  }
}

@media (min-width: 420px) {
  .-fx-image-gal {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .-fx-gal-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .-fx-gal-item {
    width: 33.33%;
  }
}

@media only screen and (max-width: 480px) {
  /* Smartphone view: 1 tile */
  .box-l {
    width: 100%;
    padding-bottom: 73%;
  }
}

@media only screen and (max-width: 650px) and (min-width: 481px) {
  /* Tablet view: 2 tiles */
  .box-l {
    width: 50%;
    padding-bottom: 50%;
  }
}

@media only screen and (max-width: 1050px) and (min-width: 651px) {
  /* Small desktop / ipad view: 3 tiles */
  .box-l {
    width: 33.3%;
    padding-bottom: 33.3%;
  }
}

@media only screen and (max-width: 1290px) and (min-width: 1051px) {
  /* Medium desktop: 4 tiles */
  .box-l {
    width: 25%;
    padding-bottom: 25%;
  }
}

/* @media (min-width: 1024px) {
  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;

  }

  .photo-container {
    margin-bottom: 3em;
    text-align: center;

    flex-grow: 1;
  }
} */

@media (min-width: 1600px) {
  .photo-container {
    margin-bottom: 4em;
    width: 30%;
  }

  h2 {
    line-height: 1;
  }
}

@media (min-width: 769px) and (max-width: 1300px) {
  .img-app {
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}

@media (max-width: 1300px) {
  .text-banner h1 {
    color: #fff;
    font-size: 51px;
    font-weight: 400;
  }

  .gallery-b {
    gap: 0px;
    width: 100%;
    height: auto;
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    top: -54px;
    position: relative;
  }
}

@media (max-width: 1200px) {
  .org-chart .board ul #f-li:before {
    content: "";
    display: block;
    position: absolute;
    height: 81px;
    width: 0px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 49%;
    top: 90px;
  }
  .org-chart ul.columnThree30:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    height: 4px;
    left: -174px;
    margin: 0 auto;
    position: relative;
    top: 54px;
    width: 11%;
  }

  .org-chart .board ul .first-li:before {
    border-left: 4px solid #888;
    height: 54px;
    top: 100px;
  }

  .text-banner h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 400;
  }

  .yes {
    left: calc(50% - 208px);
    top: 52px;
    width: 31px;
  }

  .no {
    left: calc(50% - 385px);
    top: 134px;
    width: 23px;
  }

  /* 
    .photo1 {
      float: right;
      height: 152px;
      margin-top: -168px;
      position: relative;
      width: auto;
  } */

  .banner-p {
    font-size: 20px;
    line-height: 20px;
  }
.banner-p {
    font-size: 20px;
    line-height: 20px;
}
  .quote-n {
    font-size: 20px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: 20px;
  }
}

@media (max-width: 1110px) {
  .features-title2 {
    margin-left: 21px;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 40px;
    width: 31px;
    left: calc(50% - 169px);
    font-size: 12px;
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 101px;
    width: 23px;
    left: calc(50% - 324px);
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .box {
    display: inline-block;
    width: 23%;
    /* height: 15em; */
    text-align: center;
    /* line-height: 15em; */
    color: #fff;
    margin: 3px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }
  .org-chart .board ul .first-li:before {
    border-left: 4px solid #888;
    height: 54px;
    margin-left: 49%;
    top: 90px;
  }

  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 87px !important;
    margin-left: 39% !important;
    position: absolute !important;
    top: 58px !important;
    width: 0 !important;
  }

  .enq-sub:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    float: left;
    height: 4px;
    margin: 0 auto;
    position: relative;
    top: 9px;
    width: 16%;
  }

  .org-chart ul.columnThree30:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    height: 4px;
    left: -158px;
    margin: 0 auto;
    position: relative;
    top: 52px;
    width: 11%;
  }

  .org-chart ul.columnThree30 li:last-child {
    float: left;
    margin-left: 46px;
    margin-top: 17px;
    width: 30%;
  }

  .org-chart ul.columnThree40 li:first-child {
    float: right;
    margin-left: 47px;
    margin-top: -11px;
    width: 30%;
  }
  .org-chart ul.columnThree40 li:last-child {
    float: right;
    left: 7px;
    margin-left: 25px;
    margin-right: 5px;
    margin-top: 38px;
    width: 30%;
  }

  .yes {
    left: calc(50% - 173px);
    top: 51px;
    width: 31px;
  }

  .no {
    left: calc(50% - 324px);
    top: 117px;
    width: 23px;
  }

  .org-chart ul.columnThree40:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    height: 4px;
    left: calc(50% - 198px);
    margin: 0 auto;
    position: relative;
    top: 68px;
    width: 35%;
  }
  .org-chart ul.columnOne:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    margin: 0 auto;
    position: relative;
    top: 47px;
    width: 62%;
    left: 1px;
    top: 46px;
    width: 61%;
  }
  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
  }

  .photo-container {
    margin-bottom: 3em;
    text-align: center;
    /* width: 25%;   */
    flex-grow: 1;
  }
}
@media (max-width: 1199px) {
  .org-chart .board ul .first-li:before {
    border-left: 4px solid #888;
    height: 54px;
    top: 91px;
  }
  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 87px !important;
    margin-left: 40% !important;
    position: absolute !important;
    top: 58px !important;
    width: 0 !important;
  }
  .org-chart ul.columnOne:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    margin: 0 auto;
    position: relative;
    top: 43px;
    width: 65%;
  }
  .yes {
    left: calc(50% - 185px);
    top: 52px;
    width: 31px;
  }
  .no {
    left: calc(50% - 370px);
    top: 128px;
    width: 23px;
  }
  .grid-item-1 h1 {
    color: #509507;
    font-weight: 700;
    font-size: 34px;
    font-family: "roboto", sans-serif;
    text-align: right;
    line-height: 35px;
    margin-bottom: 24px;
  }
  .grid-item-1 p {
    color: rgba(64, 64, 64, 1);
    font-size: 28px;
    font-weight: 500;
    line-height: 31px;
    text-align: right;
  }
}
@media (max-width: 820px) {
  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 74px !important;
    margin-left: 43% !important;
    position: absolute !important;
    top: 54px !important;
    width: 0 !important;
  }
}

@media (max-width: 991px) {
  .tn1 {
    margin-top: 53px !important;
  }

  .org-chart ul.columnThree30:before {
    content: "";
    display: block;
    position: relative;
    width: 11%;
    height: 4px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 39px;
    left: -102px;
  }

  .org-chart ul li.rgt2:before {
    left: -11px;
    content: "";
    display: block;
    position: relative;
    width: 1%;
    height: 66px;
    margin: 0 auto;
    bottom: 13px;
    border-right: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  }

  .org-chart .board ul .first-li:before {
    content: "";
    display: block;
    position: absolute;
    height: 52px;
    width: 0px;
    border-left: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 41%;
    top: 74px;
  }

  .org-chart .board ul #f-li:before {
    content: "";
    display: block;
    position: absolute;
    height: 74px;
    width: 0px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 49%;
    top: 82px;
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 93px;
    width: 23px;
    left: calc(50% - 273px);
    font-size: 12px;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 40px;
    width: 31px;
    left: calc(50% - 136px);
    font-size: 12px;
  }

  .quote-n {
    font-size: 20px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: -10px;
  }

  .enq-sub::before {
    content: "";
    display: block;
    position: relative;
    width: 13%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 11px;
    left: calc(50% - 130px);
    float: left;
  }
  .grid-cols-2 {
    width: 100%;
    height: 100%;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    /* vertical-align: middle; */
    align-items: center;
  }
}

@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }

  .h-blog-s {
    text-align: center;
    font-size: 4.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding: 0px 80px 0px;
  }
}

@media (max-width: 900px) {
  .img-row {
    display: block;
    justify-content: center;
  }

  .text-banner h1 {
    color: #fff;
    font-size: 27px;
    font-weight: 400;
  }

  /* .text-banner {
    position: absolute;
    top: 43%;
    bottom: 0%;
    left: 4%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 4%;
} */
  .form-contain {
    position: absolute;
    right: 0;
    margin: 135px 24px 83px;
    max-width: 300px;
    padding: 1px 23px 6px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    width: 488px;
    height: 342px;
  }

  .circle {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .circle-1 {
    background: rgba(243, 200, 255, 1);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 58px 56px 68px;
  }

  .circle-2 {
    background: rgba(254, 247, 199, 1);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 69px 54px 57px;
  }

  .circle-3 {
    background: rgba(236, 255, 218, 1);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    padding: 56px 46px 56px;
  }
}

@media (max-width: 820px) {
  .strip-main h1 {
    color: #fff !important;
    line-height: 39px !important;
    text-shadow: 1px 1px 2px #0e3400, 0 0 1em #000, 0 0 0.2em #000;
    font-size: 36px;
  }
  .org-chart ul.rgt11 li {
    left: 57px;
    margin: 0 auto;
    top: 58px;
    width: 30%;
    /* margin-left: 349px; */
  }

  .org-chart ul li.rgt2:before {
    border-right: 4px solid #888;
    bottom: 3px;
    height: 66px;
    left: -11px;
    width: 1%;
  }

  .org-chart ul.columnThree30 li:first-child {
    float: left;
    margin-left: 0;
    margin-top: 14px;
    width: 30%;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 54px;
    width: 31px;
    left: calc(50% - 137px);
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 110px;
    width: 23px;
    left: calc(50% - 228px);
  }

  .container {
    width: unset;
  }

  .box {
    display: inline-block;
    width: 45%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .boxes {
    /* width: 50%; */
    /* margin: 0 auto; */
    justify-content: center;
    display: inline-block;
  }

  .top-img {
    order: 2;
  }

  .md2:before {
    content: "" !important;
    display: block !important;
    position: absolute !important;
    height: 74px !important;
    width: 0px !important;
    border-left: 4px solid rgb(136 136 136) !important;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2) !important;
    margin-left: 43% !important;
    top: 49px !important;
  }

  .tn1 {
    margin-top: 26px !important;
  }

  .org-chart ul.columnThree30:before {
    content: "";
    display: block;
    position: relative;
    width: 11%;
    height: 4px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 41px;
    left: -102px;
  }

  .org-chart ul.columnThree40:before {
    content: "";
    display: block;
    position: relative;
    width: 35%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 72px;
    left: calc(50% - 198px);
  }

  .org-chart ul.columnThree30 li:last-child {
    width: 30%;
    float: left;
    margin-left: 39px;
    margin-top: 17px;
  }

  .org-chart ul.columnThree40 li:last-child {
    width: 30%;
    float: right;
    margin-left: 27px;
    margin-top: 38px;
    left: -8px;
    margin-right: -17px;
  }
}

@media (max-width: 800px) {
  .quote-n {
    font-size: 17px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: -4px;
  }

  .box-z {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -1px 0px;
    padding: 10px;
    width: 100%;
    min-height: 200px;
    color: #fff;
  }
  .grid-cols-2 {
    width: 100%;
    height: 100%;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    /* vertical-align: middle; */
    align-items: center;
  }
  .text-banner h1 {
    width: 351px;
    color: #fff;
    font-size: 45px;
    font-weight: 400;
  }
  .grid-item-1 h1 {
    color: #509507;
    font-weight: 700;
    font-size: 33px;
    font-family: "roboto", sans-serif;
    text-align: right;
    line-height: 36px;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .quote-n {
    font-size: 14px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: -5px;
  }

  .banner-p {
    font-size: 15px;
    line-height: 20px;
  }
  .box-z {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -5px 0;
    padding: 10px;
    width: 100%;
    min-height: 200px;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .grid-item-1 {
    padding-left: 0rem;
  }

  .main-heading {
    font-size: 35px;
  }

  .view_more_btn {
    width: 140px;
    height: 55px;
    font-size: 13.5px;
    margin-right: 1rem;
  }

  .grid-cols-3 {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .featured_info p {
    line-height: 23px;
    font-size: 14px;
  }
}

@media (max-width: 492px) {
  /* .photo1 {
    width: auto;
    height: 166px;
    position: relative;
    margin-top: -179px;
    float: right;
  } */
}

@media (max-width: 470px) {
  .strip-main h1 {
    color: #fff !important;
    line-height: 31px !important;
    text-shadow: 1px 1px 2px #0e3400, 0 0 1em #000, 0 0 0.2em #000;
    font-size: 26px;
  }
  .quote-n {
    font-size: 14px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: 12px;
  }

  .banner-p {
    font-size: 11px;
    line-height: 18px;
  }

  /* .photo1 {
    width: auto;
    height: 155px;
    position: relative;
    margin-top: -172px;
    float: right;
  } */

  .grid-cols-2 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0rem;
    /* vertical-align: middle; */
    align-items: flex-start;
  }

  .info-text-m {
    font-family: "American Typewriter", serif;
    font-weight: 400;
    line-height: 26px;
    font-size: 14px;
    text-align: left;
    padding: 0.3rem;
    padding-bottom: 33px;
  }

  .grid-item-1 h1 {
    color: #509507;
    font-weight: 400;
    font-size: 32px;
    /* font-family: 'American Typewriter', serif; */
    /* font-family: 'Arapey', serif; */
    text-align: left;
    line-height: 29px;
  }
}

@media (max-width: 405px) {
  .quote-n {
    font-size: 14px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: -12px;
  }
}

/* 768px */
@media screen and (max-width: 48em) {
  .boxes {
    display: table;
    width: 100%;
  }

  .box {
    display: block;
    width: 100%;
  }

  .one {
    display: table-footer-group;
  }
}

@media only screen and (min-width: 768px) {
  .nav__toggle {
    display: none;
  }

  .nav__menu {
    flex-direction: row;
  }

  .nav__menu li {
    display: block;
  }
}

@media (max-width: 768px) {
  .md2:before {
    content: "" !important;
    display: block !important;
    position: absolute !important;
    height: 74px !important;
    width: 0px !important;
    border-left: 4px solid rgb(136 136 136) !important;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2) !important;
    margin-left: 44% !important;
    top: 55px !important;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 47px;
    width: 31px;
    left: calc(50% - 138px);
    /* float: left; */
  }

  .org-chart ul.columnThree40 li:last-child {
    width: 30%;
    float: right;
    margin-left: 27px;
    margin-top: 38px;
    left: 7px;
    margin-right: -5px;
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 106px;
    width: 23px;
    left: calc(50% - 231px);
  }

  .md-pb {
    /* text-align: center; */
  }

  .top-img {
    order: 2;
  }

  .text-banner h1 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin-left: 5px;
  }

  .box {
    display: inline-block;
    width: 41%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  /* .form-contain {
    margin: 657px 46px 83px;
} */
  /* #box-second{
  margin-top: 388px!important;
  background: #000!important;
} */
  .circle {
    display: grid;
    align-items: center;
    gap: 10px;
    text-align: center;
    justify-content: center;
  }

  .feature-img {
    width: 100%;
  }

  .features-title p {
    display: table-cell;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .features-title2 h2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 23px;
    text-align: left;
    margin-top: 12px;
  }
  .team_img_wrapper {
    width: unset;
    max-width: 100%;
    height: 440px;
    position: absolute;
    margin-right: 0px;
    /* left: 50%; */
    /* bottom: 78px; */
    bottom: -68px;
  }

  .grid-item-2 {
    order: 1;
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .team_img_wrapper img {
    width: auto;

    height: 88%;

    object-fit: contain;
  }
  .bg-wrap {
    background-image: url(../images/webp/main-banner_resized.webp);
    padding-top: 156px;
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 78vh;
    margin-top: -4px;
    /* min-height: 673px; */
    position: relative;
    vertical-align: middle;
    /* align-items: flex-start; */
  }
}

@media (max-width: 767px) {
  /* .banner-p {
    padding-top: 25px;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    font-style: oblique;
    padding-right: 114px;
    line-height: 21px;
  } */

  .quote-n {
    font-size: 18px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: 13px;
  }

  .org-chart ul.columnThree30:before {
    content: "";
    display: block;
    position: relative;
    width: 16%;
    height: 4px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 44px;
    left: -69px;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 40px;
    width: 31px;
    left: calc(50% - 115px);
    font-size: 12px;
    /* float: left; */
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 86px;
    width: 23px;
    left: calc(50% - 208px);
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .quote-n {
    display: block;
    font-size: 19px;
    font-weight: 400;
    margin-top: 9px;
    text-align: right;
    width: 89%;
  }
  /* .photo1 {
    float: right;
    height: 152px;
    margin-top: -163px;
    position: relative;
    width: auto;
} */

  /* .banner-p {
    color: #fff;
    font-style: oblique;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    padding-right: 114px;
    padding-top: 22px;
  } */
}

@media (max-width: 520px) {
  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 40px;
    width: 31px;
    left: calc(50% - 87px);
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 143px;
    width: 23px;
    left: calc(50% - 154px);
    font-size: 12px;
  }
}

@media (max-width: 496px) {
  .box {
    display: inline-block;
    width: 67%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .boxes {
    display: table;
    width: 100%;
    text-align: center;
  }

  .quote-n {
    font-size: 19px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: -22px;
  }

  /* .photo1 {
  width: auto;
  height: 166px;
  position: relative;
  margin-top: -199px;
  float: right;
} */
}

@media (max-width: 495px) {
    .team_img_wrapper img {
        width: auto;
        height: 63%;
        object-fit: contain;
    }
        .team_img_wrapper {
        width: unset;
        max-width: 100%;
        height: 440px;
        position: absolute;
        margin-right: 0px;
        left: 3px;
        /* bottom: 78px; */
        bottom: -101px;
    }
      .banner-p {
    font-size: 11px;
    line-height: 18px;
  }
  }
@media (max-width: 490px) {
    .team_img_wrapper img {
        width: auto;
        height: 63%;
        object-fit: contain;
    }
  .banner-p {
    font-size: 11px;
    line-height: 18px;
  }
  .quote-n {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-top: -1px;
    text-align: right;
    width: 89%;
  }
  /* .photo1 {
      float: right;
      height: 152px;
      margin-top: -166px;
      position: relative;
      width: auto;
  } */
}

@media (max-width: 478px) {
  .box {
    display: inline-block;
    width: 93%;
    /* height: 15em; */
    text-align: center;
    /* line-height: 15em; */
    color: #fff;
    margin: 3px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 0px 17px 0px;
  }
      .team_img_wrapper img {
        width: auto;
        height: 63%;
        object-fit: contain;
    }

  .nav {
    border-bottom: 1px solid rgba(230, 230, 230, 1);
    background: rgba(230, 230, 230, 1);
    justify-content: space-between;
    align-items: center;
    padding: 8px 6px 8px;
  }

  .title-wrapper h2 {
    font-weight: 100;
    font-size: 23px;
    color: rgba(8, 70, 103, 1);
    text-align: left;
  }

  .title-wrapper span {
    font-weight: 400;
    font-size: 29px;

    color: rgba(8, 70, 103, 1);
  }

  .img-log {
    width: 13%;
  }

  .org-chart ul.columnThree3:before {
    content: "";
    display: block;
    position: relative;
    width: 41%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 62px;
  }

  .org-chart .board ul .first-li:before {
    content: "";
    display: block;
    position: absolute;
    height: 62px;
    width: 0px;
    border-left: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 41%;
    top: 74px;
  }

  .md2:before {
    content: "" !important;
    display: block !important;
    position: absolute !important;
    height: 82px !important;
    width: 0px !important;
    border-left: 4px solid rgb(136 136 136) !important;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2) !important;
    margin-left: 34% !important;
    top: 55px !important;
  }

  .org-chart ul.columnOne:before {
    content: "";
    display: block;
    position: relative;
    width: 56%;
    /* height: 10px; */
    /* border-top: 2px solid orange; */
    margin: 0 auto;
    top: 47px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  }

  .enq-sub::before {
    content: "";
    display: block;
    position: relative;
    width: 8%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 10px;
    left: calc(50% - 71px);
    float: left;
  }

  .org-chart ul.columnThree40 li:last-child {
    width: 30%;
    float: right;
    margin-left: 32px;
    margin-top: 38px;
    left: 7px;
    margin-right: -14px;
  }

  .org-chart ul.columnThree40:before {
    content: "";
    display: block;
    position: relative;
    width: 34%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 68px;
    left: calc(50% - 177px);
  }

  .org-chart ul.rgt11 li {
    left: 34px;
    margin: 0 auto;
    top: 33px;
    width: 30%;
    /* margin-left: 349px; */
  }

  .yes {
    font-size: 15px;
    color: #000000;
    text-align: left;
    /* font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif); */
    position: absolute;
    top: 52px;
    width: 31px;
    left: calc(50% - 87px);
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 129px;
    width: 23px;
    left: calc(50% - 169px);
    font-size: 15px;
  }

  .org-chart ul.columnThree30 li:last-child {
    width: 30%;
    float: left;
    margin-left: 19px;
    margin-top: 17px;
  }

  .org-chart ul.columnThree40 li:first-child {
    float: right;
    margin-left: 47px;
    margin-top: -9px;
    width: 30%;
  }

  .org-chart ul.columnThree30:before {
    content: "";
    display: block;
    position: relative;
    width: 16%;
    height: 4px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 54px;
    left: -69px;
  }
}
@media (max-width: 470px) {
  .banner-strip {
    background: #81c53a;
    bottom: -57px;
    /* height: 67px; */
    margin: 0px -26px 0 -28px;
    position: absolute;
    width: 100%;
  }
  .banner-p {
    font-size: 11px;
    line-height: 18px;
  }
  .quote-n {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-top: -15px;
    text-align: right;
    width: 89%;
  }
  .grid-item-1 h1 {
    color: #509507;
    font-size: 26px;
    line-height: 30px;
    margin-top: -13px;
  }
  /* .photo1 {
      float: right;
      height: 152px;
      margin-top: -162px;
      position: relative;
      width: auto;
  } */
}

@media (max-width: 414px) {
  .grid-item-1 {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: -346px;
  }
  .grid-item-1 p {
    color: rgba(64, 64, 64, 1);
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    text-align: right;
  }
  .grid-item-1 h1 {
    color: #509507;
    font-size: 19px;
    line-height: 21px;
    margin-top: -13px;
  }
  .get-btn {
    background: linear-gradient(115.76deg, #7fc33a 20.03%, #59a028 74.31%);
    border: unset;
    border-radius: 8px;
    padding: 9px 27px 9px 27px;
    font-size: 13px;
    color: rgba(255, 255, 255, 1);
    text-decoration: unset !important;
    font-weight: 600;
  }
}

@media (max-width: 400px) {
  .strip-main h1 {
    color: #fff !important;
    line-height: 23px !important;
    text-shadow: 1px 1px 2px #0e3400, 0 0 1em #000, 0 0 0.2em #000;
    font-size: 19px;
  }
  .grid-item-1 {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: -47px;
  }
  .features-title h2 {
    color: #000;
    font-size: 27px;
    font-weight: 400;
    margin-left: 0;
    text-align: left;
  }

  .manage1 {
    top: 26px !important;
  }

  .org-chart ul li.rgt2:before {
    border-right: 4px solid #888;
    bottom: 12px;
    height: 66px;
    left: -11px;
    width: 1%;
  }
  .org-chart ul.columnThree30 li:first-child {
    float: left;
    margin-left: 0;
    margin-top: 39px;
    width: 30%;
  }

  .foot-add {
    width: 228px;
  }

  .d-ar1 {
    margin-bottom: 220px !important;
  }

  .sec-p1 {
    margin-top: -11px;
    position: relative;
    z-index: 1;
  }

  .mob-img1 {
    width: 62%;
  }

  .org-chart ul.columnThree40 li:last-child {
    width: 30%;
    float: right;
    margin-left: 27px;
    margin-top: 38px;
    left: 14px;
  }

  .org-chart .board ul .first-li:before {
    content: "";
    display: block;
    position: absolute;
    /* height: 70px; */
    width: 0px;
    border-left: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 45%;
    height: 110px;
    top: 38px;
  }

  .yes {
    color: #000;
    font: 400 16px/24px Roboto, sans-serif;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    font-size: 12px;
    left: calc(50% - 76px);
    position: absolute;
    text-align: left;
    top: 44px;
    width: 31px;
  }

  /* .org-chart .board ul .first-li:before {
    height: 65px;
    top: 71px;
  } */

  .org-chart ul.columnOne:before {
    width: 57%;
    left: 9px;
  }

  .org-chart .board ul .md1:before {
    margin-top: 12px;
    margin-left: 42% !important;
    height: 54px;
  }

  .org-chart ul.columnThree40:before {
    left: calc(50% - 122px);
    top: 80px;
  }

  .org-chart ul.columnThree30 li:last-child {
    float: left;
    margin-left: 16px;
    margin-top: 61px;
    width: 30%;
  }

  .org-chart ul.columnThree30:before {
    border-left: 4px solid #fff;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    height: 4px;
    left: -56px;
    margin: 0 auto;
    position: relative;
    top: 98px;
    width: 16%;
  }

  .img-log {
    width: 33%;
    padding: 0px;
  }

  .med-on {
    margin-top: 52px !important;
  }
  .org-chart .board:before {
    height: 94%;
  }

  .org-chart ul.rgt11 li {
    left: 34px;
    margin: 0 auto;
    top: 41px;
    width: 35%;
  }

  .tn1 {
    margin-top: 48px !important;
  }

  .org-chart ul.columnThree3:before {
    content: "";
    display: block;
    position: relative;
    width: 41%;
    height: 4px;
    border-right: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 91px;
  }
  /* .banner-p {
    padding-top: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #ffff;
    padding-right: 114px;
    line-height: 15px;
    font-style: oblique;
  } */

  .quote-n {
    font-size: 14px;
    display: block;
    width: 89%;
    text-align: right;
    font-weight: 400;
    margin-top: 13px;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    font-weight: 400;
    line-height: 23px;
    width: 289px;
  }
  /* 
  .photo1 {
    float: right;
    height: 152px;
    margin-top: -153px;
    position: relative;
    width: auto;
  } */

  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 100px !important;
    margin-left: 43% !important;
    position: absolute !important;
    top: 47px !important;
    width: 0 !important;
  }

  .enq-sub::before {
    content: "";
    display: block;
    position: relative;
    width: 15%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 9px;
    left: calc(50% - 64px);
    float: left;
  }
  .head-m {
    font-size: 14px;
    text-align: left;
  }
  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 141px;
    width: 23px;
    left: calc(50% - 156px);
    font-size: 15px;
  }

  .enq-sub {
    width: 35% !important;
    margin: 0px auto !important;
    top: -28px !important;
  }
  .title-about h2 {
    color: #000;
    font-size: 26px;
    font-weight: 400;
    text-align: left;
  }
  .box-z {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -6px 0;
    padding: 10px;
    width: 100%;
    min-height: 200px;
    color: #fff;
  }
  .bg-wrap {
    background-image: url(../images/webp/main-banner_resized.webp);
    padding-top: 143px;
    align-items: flex-start;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 51vh;
    margin-top: -4px;
    /* min-height: 673px; */
    position: relative;
    vertical-align: middle;
  }
  .grid-cols-2 {
    width: 100%;
    height: 100%;
    display: unset;
    vertical-align: middle;
  }
  .grid-item-1 p {
    color: rgba(64, 64, 64, 1);
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
  .grid-item-1 h1 {
    color: #509507;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 1px;
  }
  .get-btn {
    background: linear-gradient(115.76deg, #7fc33a 20.03%, #59a028 74.31%);
    border: unset;
    border-radius: 8px;
    padding: 8px 27px 8px 27px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    text-decoration: unset !important;
    font-weight: 600;
  }
  .team_img_wrapper {
    height: 149px;
    width: 273px;
    bottom: 0px;
  }

  .team_img_wrapper img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
  .photo1 {
    width: auto;
    height: 126px;
    position: relative;
    /* margin-top: -180px; */
    float: right;
    bottom: 118px;
  }
}

@media (max-width: 360px) {
  /* .banner-p {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    padding-right: 114px;
    padding-top: 10px;
    font-style: oblique;
  } */

  /* .grid-item-1 h1 {
  color: #509507;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 1px;
} */
  /* .photo1 {
  float: right;
  height: 152px;
  margin-top: -162px;
  position: relative;
  width: auto;
} */
  .manage2 {
    top: 23px !important;
  }
  .manage1 {
    top: 39px !important;
  }
  .org-chart .board ul .first-li:before {
    content: "";
    display: block;
    position: absolute;
    /* height: 70px; */
    width: 0px;
    border-left: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 43%;
    height: 108px;
    top: 38px;
  }
  .yes {
    color: #000;
    font: 400 16px/24px Roboto, sans-serif;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    font-size: 12px;
    left: calc(50% - 71px);
    position: absolute;
    text-align: left;
    top: 45px;
    width: 31px;
  }
  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 149px;
    width: 23px;
    left: calc(50% - 144px);
    font-size: 15px;
  }

  .enq-sub::before {
    content: "";
    display: block;
    position: relative;
    width: 13%;
    height: 4px;
    border-top: 4px solid rgb(136 136 136);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    top: 4px;
    left: calc(50% - 52px);
    float: left;
  }
  .enq-sub {
    width: 35% !important;
    margin: 0px auto !important;
    top: -42px !important;
  }
  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 100px !important;
    margin-left: 43% !important;
    position: absolute !important;
    top: 45px !important;
    width: 0 !important;
  }
  /* .grid-item-1 p {
  color: rgba(64, 64, 64, 1);
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
} */

  .banner-strip {
    background: #81c53a;
    bottom: -64px;
    /* height: 59px; */
    margin: 15px -26px 0 -28px;
    position: absolute;
    width: 100%;
  }
  .photo1 {
    width: auto;
    height: 113px;
    position: relative;
    /* margin-top: -180px; */
    float: right;
    bottom: 120px;
  }

  .team_img_wrapper {
    height: 149px;
    width: 273px;
    bottom: -14px;
  }
  .grid-item-1 {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: -38px;
  }
}

@media (max-width: 320px) {
  .team_img_wrapper {
    height: 262px;
    width: 257px;
  }
}

@media (max-width: 300px) {
  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 44px;
    width: 31px;
    left: calc(50% - 61px);
    font-size: 12px;
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 114px;
    width: 23px;
    left: calc(50% - 105px);
    font-size: 12px;
  }
}

@media only screen and (max-width: 280px) {
  .box-l {
    padding-bottom: 97%;
    width: 100%;
  }
}

@media (max-width: 280px) {
  .features-title h2 {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    margin-left: 0;
    text-align: left;
  }
  .features-title2 h2 {
    font-size: 20px;
    margin-top: 12px;
  }
  .org-chart ul li span {
    background: #f3c8fe;
    border: 4px solid #888;
    border-radius: 24px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    color: #000;
    display: block;
    font-size: 12px;
    height: 68px;
    height: auto;
    overflow: hidden;
    padding: 4px;
    text-align: center;
    text-decoration: none;
    transition: all 0.1s linear;
    line-height: 12px;
    padding: 9px;
  }

  .org-chart .board:before {
    height: 99%;
  }

  .md2:before {
    border-left: 4px solid #888 !important;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2) !important;
    content: "" !important;
    display: block !important;
    height: 100px !important;
    margin-left: 43% !important;
    position: absolute !important;
    top: 28px !important;
    width: 0 !important;
  }

  .yes {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 59px;
    width: 31px;
    left: calc(50% - 59px);
    font-size: 11px;
  }

  .org-chart .board ul .first-li:before {
    height: 62px;
    margin-left: 41%;
    top: 66px;
  }

  .org-chart .board ul #f-li:before {
    content: "";
    display: block;
    position: absolute;
    height: 49px;
    width: 0px;
    border-left: 4px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    margin-left: 49%;
    top: 77px;
  }

  .org-chart ul.rgt11 li {
    left: 24px;
    margin: 0 auto;
    top: 52px;
    width: 37%;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    font-weight: 400;
    line-height: 18px;
    width: 257px;
  }

  .footer-social-icon a {
    color: #fff;
    font-size: 16px;
    margin-right: 28px;
  }

  .org-chart ul.columnThree40:before {
    left: calc(50% - 81px);
    top: 89px;
  }

  /* .org-chart ul.columnThree40 li:first-child {
  width: 30%;
  float: right;
  margin-left: 26px;
  margin-top: 3px;
} */
  .org-chart ul.columnThree30 li:last-child {
    width: 30%;
    float: left;
    margin-left: 13px;
    margin-top: 45px;
  }

  .demo1 {
    top: 47px;
  }

  .org-chart .board:before {
    height: 99%;
  }

  .content-top {
    margin-top: 201px;
  }

  /* .banner-p {
    padding-top: 12px;
    font-size: 10px;
    font-weight: 400;
    color: #000;
    padding-right: 114px;
    line-height: 12px;
  } */
  .photo1 {
    width: auto;
    height: 114px;
    position: relative;
    /* margin-top: -180px; */
    float: right;
    bottom: 120px;
  }

  .quote-n {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin-top: 14px;
    text-align: right;
    width: 89%;
  }

  .img-btn {
    width: 93px;
  }

  .box {
    display: inline-block;
    width: 93%;
    /* height: 15em; */
    text-align: center;
    /* line-height: 15em; */
    color: #fff;
    margin: 3px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 0px 17px 0px;
  }

  .team_img_wrapper {
    width: 215px;
    height: 262px;
  }

  .grid-item-1 h1 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 21px;
    font-family: "American Typewriter", serif;
    text-align: left;
    line-height: 21px;
  }

  .info-text-m {
    font-family: "American Typewriter", serif;
    font-weight: 400;
    line-height: 15px;
    font-size: 11px;
    text-align: left;
    padding: 0.3rem;
    padding-bottom: 33px;
  }

  .banner-strip {
    margin-right: -26px;
    margin-left: -28px;
    background: #81c53a;
    /* height: 71px; */
    margin-top: 15px;
    margin-bottom: -34px;
    bottom: 0;
    position: absolute;
    width: 100%;
  }

  /* .photo1 {
    float: right;
    height: 145px;
    margin-top: -155px;
    position: relative;
    width: auto;
  } */

  .org-chart ul.columnOne:before {
    width: 51%;
    left: 9px;
  }

  .c-p1 {
    top: 38px;
  }

  .org-chart ul.columnThree40 li:last-child {
    width: 30%;
    float: right;
    margin-left: 27px;
    margin-top: 56px;
    left: 22px;
  }

  .org-chart ul.columnThree40 li:first-child {
    float: right;
    margin-left: 47px;
    margin-top: -5px;
    width: 30%;
  }

  .no {
    color: #000000;
    text-align: left;
    font: var(--material-theme-body-large, 400 16px/24px "Roboto", sans-serif);
    position: absolute;
    top: 147px;
    width: 23px;
    left: calc(50% - 112px);
    font-size: 12px;
  }

  .circle-1 {
    margin-bottom: 165px;
  }

  .circle-2 {
    margin-bottom: 165px;
  }

  .circle-3 {
    margin-bottom: 165px;
  }

  .org-chart ul li.rgt2:before {
    border-right: 4px solid #888;
    bottom: 9px;
    height: 77px;
    left: -6px;
    width: 1%;
  }

  .enq-sub:before {
    border-top: 4px solid #888;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    float: left;
    height: 4px;
    left: calc(50% - 40px);
    margin: 0 auto;
    position: relative;
    top: 3px;
    width: 13%;
  }

  .box-l {
    padding-bottom: 97%;
    width: 100%;
  }
  .team_img_wrapper img {
    display: none;
  }
}
