.icon-n {
    margin: 52px -249px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}

.icon-m1 {
    margin: 51px -250px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}

.icon-m2 {
    margin: 52px -249px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}

.react-tel-input .form-control:focus {
    background-color: transparent !important;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-tel-input .form-control {
    font-size: 16px;
    width: 100% !important;
    background: transparent !important;
    border: -11px solid #CACA;
    border-radius: unset !important;

    outline: none !important;
    padding: 18.5px 14px 18.5px 45px !important;
    transition: box-shadow ease .25s, border-color ease .25s;
    color: white;
}

.search .form-group input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    margin: 5px 0px 3px;
    color: #000 !important;
    padding: 0px 28px 0px;
}

.react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    margin-left: -5px !important;
}

.success-msg {
    color: white;

}