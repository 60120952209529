.cleanworld .about-p1{
    font-size: 1.5rem!important;
    font-weight: 400!important;
    color: rgb(8 8 8)!important;
    text-align: left!important;
    margin-top: 15px!important
}
.cleanworld .about-h1 {
    font-size: 4rem!important;
    font-weight: 400!important;
    color: rgba(0, 0, 0, 1)!important;
    text-align: left!important;
    line-height: 45px!important;
    margin-top: 92px;
}
.cleanworld .about li{
    display: list-item;
    margin-left: 41px;
    font-size: 1.3rem!important;
    
}
.cleanworld  li{
    text-align: justify;
    margin-left: 42px;
}